import {useRef, useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import { MortgageModel , MortgageValues } from '../helper/Mortgage/_model'
import { calculateMortgageValues } from '../helper/Mortgage/calculator'

export function useMortgageProjections() {
  // Define the validation schema using Yup
  const MortgageSchema = Yup.object().shape({
    home_price: Yup.number()
      .min(0, 'Please enter home price greater than or equal to $0')
      .max(10000000, 'Please enter home price less than or equal to $10,000,000')
      .typeError('Please enter home price greater than or equal to $0')
      .required('Please enter the home price'),

    down_payment: Yup.number()
      .min(0, 'Please enter down payment value greater than or equal to $0')
      .max(10000000, 'Please enter down payment value less than or equal to 10000000')
      .typeError('Please enter down payment value amount greater than or equal to 0')
      .required('Please enter the down payment value'),

    loan_years: Yup.number()
      .min(1, 'Please enter a number of years greater than or equal to 1')
      .max(100, 'Please enter a number of years less than or equal to 100')
      .typeError('Please enter number of years greater than or equal to 1')
      .required('Please enter the number of years to grow'),

    interest_rate: Yup.number()
      .min(0, 'Please enter annualized interest rate greater than or equal to $0')
      .max(100, 'Please enter annualized interest rate less than or equal to 100%')
      .typeError('Please enter annualized interest rate greater than or equal to $0'),
  })
  // Define the initial form values
  const initialValues = {
        home_price: 100000,
        down_payment: 20000,
        loan_years: 10,
        interest_rate: 5,
      }
  const isFirstRender = useRef(true)
 
  const generateData = (dataObj: MortgageModel) => {
    dataObj.home_price = dataObj.home_price ? dataObj.home_price : 0
    dataObj.down_payment = dataObj.down_payment ? dataObj.down_payment : 0
    dataObj.loan_years = dataObj.loan_years ? dataObj.loan_years : 0
    dataObj.interest_rate = dataObj.interest_rate ? dataObj.interest_rate : 0
 

    const barsDataArrayToShowOnChart: MortgageValues[] = calculateMortgageValues(
      dataObj.home_price,
      dataObj.down_payment,
      dataObj.loan_years,
      dataObj.interest_rate
    )
    return barsDataArrayToShowOnChart
  }
  const formik = useFormik<MortgageModel>({
    initialValues,
    enableReinitialize: true,
    validationSchema: MortgageSchema,
    onSubmit: () => {},
  })

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
    }
  }

  return {
    isFirstRender,
    handleKeyPress,
    formik,
    initialValues,
    generateData,
  }
}
