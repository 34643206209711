import {memo} from 'react'
import MortgageSection from '../../modules/homeComponents/Mortgage/MortgageSection'
import {useLocation} from 'react-router-dom'
const MortgagePage: React.FC = (props) => {
  const location = useLocation()
  const pathsToCheckForWaystone = [
    'Style=Waystone',
    'style=waystone',
    'Style=waystone',
    'style=Waystone',
  ]

  const isWayStoneStyledInUrl = pathsToCheckForWaystone.some(
    (path) => location.search.includes(path) || location.pathname.slice(1) === path
  )
  return (
    <section
      className={`container-custom container ${
        isWayStoneStyledInUrl ? 'container-waystone hide-scroll-bar' : ''
      } content`}
    >
      <MortgageSection />
    </section>
  )
}

export default memo(MortgagePage)
