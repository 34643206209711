/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {AdminRoutes} from './AdminRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage, useAuth} from '../modules/auth'
import {App} from '../App'
import {UserRoles} from '../config/UserRoles'
import {UserRoutes} from './UserRoutes'
import {HomePage} from '../user/pages/home/HomePage'
import {ARQPage} from '../user/pages/arq/ARQPage'
import MapOverlayTool from '../user/pages/maps/MapOverlayTool'
import AboutPage from '../user/pages/about/AboutPage'
import SBLOCPage from '../user/pages/sblocPage/sblocPage'
import IBLOCPage from '../user/pages/IBLOCPage/IBLOCPage'
import MortgagePage from '../user/pages/mortgagePage/MortgagePage'
import {MobileHomePage} from '../user/pages/home/MobileHomePage'
import MapComponentWrapper from '../user/pages/maps/MapComponentWrapper'
/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  const {currentUser} = useAuth()
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          {currentUser ? (
            <>
              {/* if loggedIn user is admin then navigate to admin routes */}
              {currentUser.user_role === UserRoles.admin ? (
                <>
                  <Route path='/*' element={<AdminRoutes />} />
                  <Route index element={<Navigate to='/dashboard' />} />
                </>
              ) : null}
              {currentUser.user_role === UserRoles.user ? (
                <>
                  <Route index element={<Navigate to='/home' />} />
                  <Route path='/*' element={<UserRoutes />} />
                  <Route index element={<Navigate to='/' />} />
                </>
              ) : null}
            </>
          ) : (
            <>
              <Route path='/home' element={<HomePage />} />
              <Route path='/arq/home' element={<ARQPage />} />
              <Route path='/realestate/home' element={<ARQPage />} />
              <Route path='/maps' element={<MapComponentWrapper />} />
              <Route path='/sblocPage' element={<SBLOCPage />} />
              <Route path='/mortgage-calculator' element={<MortgagePage />} />
              <Route path='/iblocPage' element={<IBLOCPage />} />
              <Route path='/about' element={<AboutPage />} />
              <Route path='/arq-mobile' element={<MobileHomePage />} />
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/home' />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
