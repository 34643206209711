import React, {useState, useEffect, useRef} from 'react'
import 'leaflet/dist/leaflet.css'
import {FaSearchPlus, FaSearchMinus, FaLock, FaLockOpen, FaCopy} from 'react-icons/fa'
import 'leaflet/dist/leaflet.css'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {sendAnalytics, sendToSheets} from './mapUtils'
import CopyToClipboard from 'react-copy-to-clipboard'
import {KTSVG} from '../../../../_metronic/helpers'
import {toast} from 'react-toastify'
import domtoimage from 'dom-to-image-more'
import AutocompleteField from './AutoCompleteField'
import useScreenWidth from '../../modules/hooks/useScreenWidth'
import {GoogleMap, Marker, Polyline} from '@react-google-maps/api'
// ORS API key (replace with your actual API key)
const notifySuccess = (msg) => toast.success(msg)
// Fetch road-based route from OpenRouteService
const fetchRoute = async (start, end) => {
  return {
    routeCoords: [
      {lat: start.lat, lng: start.lng},
      {lat: (start.lat + end.lat) / 2, lng: (start.lng + end.lng) / 2},
      {lat: end.lat, lng: end.lng},
    ],
    distance: '10 km',
  }
}

const MapWithRoute = ({center, zoom, start, end, isLockedOpen, onMove, onZoomChange}) => {
  const [routeCoords, setRouteCoords] = useState([])
  const [distance, setDistance] = useState(null)
  const mapRef = useRef(null)
  const [lastCenter, setLastCenter] = useState(center)
  const [isCtrlPressed, setIsCtrlPressed] = useState(false)
  const convertArrayToLatLng = (arr) => {
    if (Array.isArray(arr) && arr.length === 2) {
      const [lat, lng] = arr
      if (typeof lat === 'number' && typeof lng === 'number') {
        return {lat, lng}
      }
    }
    return null
  }

  useEffect(() => {
    const startLatLng = convertArrayToLatLng(start)
    const endLatLng = convertArrayToLatLng(end)

    if (startLatLng && endLatLng) {
      fetchRoute(startLatLng, endLatLng)
        .then(({routeCoords, distance}) => {
          setRouteCoords(routeCoords)
          setDistance(distance)
        })
        .catch((error) => console.error('Error fetching route:', error))
    } else {
      console.warn('Invalid start or end coordinates', start, end)
    }
  }, [start, end])
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Control') {
        setIsCtrlPressed(true)
      }
    }
    const handleKeyUp = (e) => {
      if (e.key === 'Control') {
        setIsCtrlPressed(false)
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    window.addEventListener('keyup', handleKeyUp)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
      window.removeEventListener('keyup', handleKeyUp)
    }
  }, [])
  const handleMapLoad = (map) => {
    mapRef.current = map
  }

  const handleDragEnd = () => {
    if (mapRef.current && !isLockedOpen) {
      const newCenter = mapRef.current.getCenter()

      // Extract latitude and longitude using .lat() and .lng()
      const lat = newCenter.lat()
      const lng = newCenter.lng()

      const delta = {
        lat: lat - lastCenter[0],
        lng: lng - lastCenter[1],
      }

      if (onMove && !isCtrlPressed) onMove(delta, [lat, lng])

      // Update the last center to the new center
      setLastCenter([lat, lng])
    }
  }

  const handleZoomChanged = () => {
    if (mapRef.current && !isLockedOpen && !isCtrlPressed) {
      const newZoom = mapRef.current.getZoom()
      if (onZoomChange) {
        onZoomChange(newZoom)
      }
    }
  }

  const startLatLng = convertArrayToLatLng(start)
  const endLatLng = convertArrayToLatLng(end)

  return (
    <div style={{width: '100%', height: '100%'}}>
      <GoogleMap
        mapContainerStyle={{width: '100%', height: '100%'}}
        center={convertArrayToLatLng(center) || {lat: 0, lng: 0}} // Fallback to default if invalid
        zoom={zoom}
        onLoad={handleMapLoad}
        onDragEnd={handleDragEnd}
        onZoomChanged={handleZoomChanged}
        options={{
          zoomControl: !isLockedOpen,
          disableDefaultUI: isLockedOpen,
        }}
      >
        {/* Start Marker */}
        {startLatLng && (
          <Marker
            position={startLatLng}
            title='Start Point'
            icon='http://maps.google.com/mapfiles/ms/icons/green-dot.png'
          />
        )}

        {/* End Marker */}
        {endLatLng && (
          <Marker
            position={endLatLng}
            title='End Point'
            icon='http://maps.google.com/mapfiles/ms/icons/red-dot.png'
          />
        )}

        {/* Route Polyline */}
        {routeCoords.length > 0 && (
          <Polyline
            path={routeCoords}
            options={{
              strokeColor: 'blue',
              strokeOpacity: 0.8,
              strokeWeight: 2,
            }}
          />
        )}
      </GoogleMap>
    </div>
  )
}

const DraggableMaps = () => {
  const lastElementRef = useRef(null)
  const getParsedParam = (param) => {
    const searchParams = new URLSearchParams(window.location.search)
    const paramValue = searchParams.get(param)

    try {
      // Attempt to parse only if it looks like JSON (starts with [ or {)
      return paramValue && (paramValue.startsWith('[') || paramValue.startsWith('{'))
        ? JSON.parse(paramValue)
        : paramValue
    } catch (e) {
      // Return the raw value if parsing fails
      return paramValue
    }
  }

  const screenWidth = useScreenWidth()
  const [opacity, setOpacity] = useState(+getParsedParam('opacity') || 0.5)
  const [advisorURL, setAdvisorURL] = useState('')
  const [isShareURL, setIsShareURL] = useState(false)
  const [copied, setCopied] = useState(false)
  const [isOverlay, setIsOverlay] = useState(getParsedParam('isOverlay') === 'true' ? true : false)
  const [zoom, setZoom] = useState(+getParsedParam('zoom') || 13)
  // State for start and end locations of New York and Lahore maps
  const [address1start, setNewYorkStart] = useState(
    getParsedParam('address1start')
      ? getParsedParam('address1start').split(',').map(Number)
      : [40.73061, -73.935242]
  )

  const [address1end, setNewYorkEnd] = useState(
    getParsedParam('address1end')
      ? getParsedParam('address1end').split(',').map(Number)
      : [40.75061, -73.975242]
  )

  const [address2start, setLahoreStart] = useState(
    getParsedParam('address2start')
      ? getParsedParam('address2start').split(',').map(Number)
      : [31.582, 74.329]
  )

  const [address2end, setLahoreEnd] = useState(
    getParsedParam('address2end')
      ? getParsedParam('address2end').split(',').map(Number)
      : [31.5546, 74.3572]
  )

  const [address1center, setNewYorkCenter] = useState(
    getParsedParam('address1center')
      ? getParsedParam('address1center').split(',').map(Number)
      : [40.73061, -73.935242]
  )

  const [address2center, setLahoreCenter] = useState(
    getParsedParam('address2center')
      ? getParsedParam('address2center').split(',').map(Number)
      : [31.5497, 74.3436]
  )

  // Input fields for city and address
  const [address1initialStart, setNewYorkStartAddress] = useState(
    getParsedParam('address1initialStart') || 'UniCredit Bank, East 42 Street, NewYork USA'
  )
  const [address1initialEnd, setNewYorkEndAddress] = useState(
    getParsedParam('address1initialEnd') || 'Rail Road Avenue, NewYork USA'
  )
  const [address2initialStart, setLahoreStartAddress] = useState(
    getParsedParam('address2initialStart') || 'Delhi Gate, Lahore, Pakistan'
  )
  const [address2initialEnd, setLahoreEndAddress] = useState(
    getParsedParam('address2initialEnd') || 'Allama Iqbal Road, Lahore, Pakistan'
  )
  const [isLockedOpen, setScrollZoomEnabled] = useState(getParsedParam('isLockedOpen') || false)

  const handleNewYorkMapMove = (delta, newCenter) => {
    // delta to set the other map's view
    setLahoreCenter((prevCenter) => [prevCenter[0] + delta?.lat, prevCenter[1] + delta.lng])
    // new center to set the own map view
    setNewYorkCenter([newCenter[0], newCenter[1]])
  }

  const handleLahoreMapMove = (delta, newCenter) => {
    // delta to set the other map's view
    setNewYorkCenter((prevCenter) => [prevCenter[0] + delta?.lat, prevCenter[1] + delta.lng])
    // new center to set the own map view
    setLahoreCenter([newCenter[0], newCenter[1]])
  }
  // Handle zoom synchronization
  const handleZoom = (zoomDelta) => {
    setZoom(zoomDelta)
  }

  // Helper function to format the date
  function getFormattedDate() {
    const now = new Date()
    const year = now.getFullYear()
    const month = String(now.getMonth() + 1).padStart(2, '0') // Months are zero-based
    const day = String(now.getDate()).padStart(2, '0')
    return `${year}-${month}-${day}`
  }

  // Helper function to format the time
  function getFormattedTime() {
    const now = new Date()
    const hours = String(now.getHours()).padStart(2, '0')
    const minutes = String(now.getMinutes()).padStart(2, '0')
    const seconds = String(now.getSeconds()).padStart(2, '0')
    return `${hours}:${minutes}:${seconds}`
  }

  // Combine the formatted date and time
  let formattedDateAndTime = getFormattedDate() + ' ' + getFormattedTime()
  sendToSheets(
    `${address1initialStart}`,
    `${address1initialEnd}`,
    `${address2initialStart}`,
    `${address2initialEnd}`,
    formattedDateAndTime
  )
  sendAnalytics('Comparing Maps')

  const handleZoomIn = () => setZoom((prevZoom) => Math.min(prevZoom + 1, 18))
  const handleZoomOut = () => setZoom((prevZoom) => Math.max(prevZoom - 1, 1))
  const toggleScrollZoom = () => {
    setScrollZoomEnabled(!isLockedOpen)
  }
  const handleCopy = () => {
    setCopied(true)
    setTimeout(() => setCopied(false), 2000) // Reset copied state after 2 seconds
  }
  const ZoomControls = ({handleZoomIn, handleZoomOut}) => {
    const screenWidth = useScreenWidth()

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: screenWidth < 768 ? 'row' : 'column',
          flexWrap: 'wrap',
          justifyContent: screenWidth < 768 ? 'center' : 'flex-start',
          alignItems: 'center',
          gap: '10px',
          marginBottom: '20px',
        }}
      >
        <button
          onClick={() => generateImage()}
          style={{
            padding: '10px',
            color: 'black',
            fontSize: '16px',
            borderRadius: '5px',
          }}
        >
          <FaCopy />
        </button>
        <button
          onClick={handleZoomIn}
          style={{
            padding: '10px',
            color: 'black',
            fontSize: '16px',
            borderRadius: '5px',
          }}
        >
          <FaSearchPlus />
        </button>
        <button
          onClick={handleZoomOut}
          style={{
            padding: '10px',
            color: 'black',
            fontSize: '16px',
            borderRadius: '5px',
          }}
        >
          <FaSearchMinus />
        </button>
        <button
          onClick={toggleScrollZoom}
          style={{
            padding: '10px',
            color: 'black',
            fontSize: '16px',
            borderRadius: '5px',
          }}
        >
          {isLockedOpen ? <FaLockOpen /> : <FaLock />}
        </button>
        <button
          onClick={() => setIsOverlay(!isOverlay)}
          style={{
            padding: '10px',
            color: 'black',
            fontSize: '16px',
            borderRadius: '5px',
          }}
        >
          <img
            alt='chloee-img'
            className='overlay-image'
            src={toAbsoluteUrl('/media/home/overlay.png')}
          />
        </button>
        <button
          onClick={() => setIsShareURL(!isShareURL)}
          style={{
            padding: '10px',
            color: 'black',
            fontSize: '16px',
            borderRadius: '5px',
          }}
        >
          <img
            alt='chloee-img'
            className='overlay-image'
            src={toAbsoluteUrl('/media/home/share.png')}
          />
        </button>
      </div>
    )
  }

  const generateImage = () => {
    // Use "map-container" if isOverlay is true, otherwise fall back to "map2"
    const chartContainer = document.getElementById(isOverlay ? 'map-container' : 'separated-maps')

    if (chartContainer) {
      domtoimage
        .toBlob(chartContainer)
        .then((blob) => {
          navigator.clipboard
            .write([
              new ClipboardItem({
                'image/png': blob,
              }),
            ])
            .then(() => {
              notifySuccess('Map image copied to clipboard')
              console.log('Map image copied to clipboard')
            })
            .catch((err) => {
              console.error('Could not copy map image to the clipboard', err)
            })
        })
        .catch((err) => {
          console.error('An error occurred capturing the map', err)
        })
    } else {
      console.error('Map container not found')
    }
  }
  useEffect(() => {
    setAdvisorURL(
      `${process.env.REACT_APP_URL}/maps?opacity=${opacity}&zoom=${zoom}&isOverlay=${isOverlay}&address1start=${address1start}&address1end=${address1end}&address2start=${address2start}&address2end=${address2end}&address1center=${address1center}&address1initialStart=${address1initialStart}&address1initialEnd=${address1initialEnd}&address2initialStart=${address2initialStart}&address2initialEnd=${address2initialEnd}&scrollZoomEnabled=${isLockedOpen}`
    )
  }, [isShareURL])
  const handleSearchForAddress1 = (startLocation, endLocation) => {
    setNewYorkStartAddress(startLocation.address)
    setNewYorkEndAddress(endLocation.address)
    setNewYorkStart([startLocation.lat, startLocation.lng])
    setNewYorkEnd([endLocation.lat, endLocation.lng])
    setNewYorkCenter([startLocation.lat, startLocation.lng])
  }
  const handleSearchForAddress2 = (startLocation, endLocation) => {
    setLahoreStartAddress(startLocation.address)
    setLahoreEndAddress(endLocation.address)
    setLahoreStart([startLocation.lat, startLocation.lng])
    setLahoreEnd([endLocation.lat, endLocation.lng])
    setLahoreCenter([startLocation.lat, startLocation.lng])
  }

  function LocationSearchBox({
    handleLocationSearch,
    addressNumber,
    defaultStart,
    defaultEnd,
    searchBoxId,
  }) {
    const [startLocation, setStartLocation] = useState({
      lat: searchBoxId === 1 ? 31.5546 : 31.582,
      lng: searchBoxId === 1 ? 74.3572 : 74.329,
      address: defaultStart,
    })
    const [endLocation, setEndLocation] = useState({
      lat: searchBoxId === 1 ? 40.75061 : 31.5546,
      lng: searchBoxId === 1 ? -73.975242 : 74.3572,
      address: defaultEnd,
    })
    return (
      <div className='location-search-box'>
        <p
          style={{
            color: 'black',
            marginBottom: '15px',
            fontWeight: 'bold',
            fontSize: '16px',
          }}
        >
          Address#{addressNumber}
        </p>

        <AutocompleteField
          placeholder='From '
          onPlaceSelected={(place) => {
            setStartLocation(place)
          }}
          defaultAddress={startLocation.address}
        />

        <AutocompleteField
          placeholder='To '
          onPlaceSelected={(place) => {
            setEndLocation(place)
          }}
          defaultAddress={endLocation.address}
        />

        <button
          onClick={() => handleLocationSearch(startLocation, endLocation)}
          style={{
            padding: '12px',
            borderRadius: '8px',
            border: 'none',
            backgroundColor: '#007BFF',
            color: 'white',
            fontWeight: 'bold',
            cursor: 'pointer',
            width: '100%',
          }}
        >
          Search
        </button>
      </div>
    )
  }

  return (
    <div
      style={{
        textAlign: 'center',
        padding: '20px',
        backgroundColor: '#ba1baa',
        color: 'white',
      }}
    >
      <h1 style={{color: 'white'}}>What's a Mile to you?</h1>
      <p style={{color: 'white'}}>
        Ever wonder if distance 'feels the same' in other places? Now you can find out. Enter two
        locations see how they compare.
      </p>

      <div className='location-search-container'>
        <LocationSearchBox
          handleLocationSearch={handleSearchForAddress1}
          addressNumber={1}
          defaultStart={address1initialStart}
          defaultEnd={address1initialEnd}
          searchBoxId={1}
        />
        <LocationSearchBox
          handleLocationSearch={handleSearchForAddress2}
          addressNumber={2}
          defaultStart={address2initialStart}
          defaultEnd={address2initialEnd}
          searchBoxId={2}
        />
        {/* Scroll Button */}
      </div>
      {!isOverlay ? (
        <>
          <div className='row'>
            {/* Zoom Controls Column */}
            <div className={`${screenWidth < 768 ? 'd-none' : 'col-1'}`}>
              {' '}
              {/* Hidden on small screens */}
              <div className='w-50'>
                <ZoomControls handleZoomIn={handleZoomIn} handleZoomOut={handleZoomOut} />
              </div>
            </div>

            {/* Maps Column */}
            <div className='col-12 col-md-10'>
              <div id={isOverlay ? 'map-container' : 'separated-maps'} className='maps-container'>
                {/* Map 1 with New York Route */}
                <div
                  className='map-item'
                  id='map1'
                  style={{
                    width: screenWidth < 768 ? '250px' : '600px',
                    height: screenWidth < 768 ? '150px' : '300px',
                  }}
                >
                  <MapWithRoute
                    key={`newYorkMap-${isLockedOpen}`}
                    center={address1center}
                    zoom={zoom}
                    start={address1start}
                    end={address1end}
                    isLockedOpen={isLockedOpen}
                    onMove={handleNewYorkMapMove}
                    onZoomChange={handleZoom}
                  />
                </div>

                {/* Map 2 with Lahore Route */}
                <div
                  id='map2'
                  style={{
                    width: screenWidth < 768 ? '250px' : '600px',
                    height: screenWidth < 768 ? '150px' : '300px',
                  }}
                >
                  <MapWithRoute
                    key={`lahoreMap-${isLockedOpen}`}
                    center={address2center}
                    zoom={zoom}
                    start={address2start}
                    end={address2end}
                    isLockedOpen={isLockedOpen}
                    onMove={handleLahoreMapMove}
                    onZoomChange={handleZoom}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className='row'>
            <div className='col-1 d-none d-md-block'>
              {' '}
              {/* Hidden on small screens */}
              <div className='w-50'>
                <ZoomControls handleZoomIn={handleZoomIn} handleZoomOut={handleZoomOut} />
              </div>
            </div>
            <div className={`${screenWidth < 768 ? 'col-12' : 'col-10'}`}>
              <div
                id={isOverlay ? 'map-container' : 'separated-maps'} // Only set id when isOverlay is true
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  gap: '20px',
                  position: 'relative',
                  alignItems: 'center',
                  ...(isOverlay
                    ? {flexDirection: 'column', alignItems: 'center'}
                    : {alignItems: 'center'}), // Adjust layout for overlay
                }}
              >
                {/* Map 1 with New York Route */}
                <div
                  id='map1'
                  style={{
                    width: screenWidth < 768 ? '300px' : '600px',
                    height: screenWidth < 768 ? '200px' : '300px',
                    border: '1px solid black',
                    opacity: isOverlay ? 1 - opacity : 1,
                    position: isOverlay ? 'absolute' : 'relative',
                    top: isOverlay ? '50px' : '0',
                    zIndex: isOverlay ? 1 : 'auto',
                    transition: 'opacity 0.3s ease',
                    justifyContent: 'center',
                  }}
                >
                  <MapWithRoute
                    key={`newYorkMap-${isLockedOpen}`}
                    center={address1center}
                    zoom={zoom}
                    start={address1start}
                    end={address1end}
                    isLockedOpen={isLockedOpen}
                    onMove={handleNewYorkMapMove}
                    onZoomChange={handleZoom}
                  />
                </div>

                {/* Map 2 with Lahore Route */}
                <div
                  id='map2'
                  style={{
                    width: screenWidth < 768 ? '300px' : '600px',
                    height: screenWidth < 768 ? '200px' : '300px',
                    border: '1px solid black',
                    opacity: isOverlay ? opacity : 1,
                    position: isOverlay ? 'relative' : 'relative',
                    top: isOverlay ? '50px' : '0',
                    zIndex: isOverlay && opacity > 0.5 ? '100' : 'auto',
                    transition: 'opacity 0.3s ease',
                  }}
                >
                  <MapWithRoute
                    key={`lahoreMap-${isLockedOpen}`} // Unique key based on isLockedOpen
                    center={address2center}
                    zoom={zoom}
                    start={address2start}
                    end={address2end}
                    isLockedOpen={isLockedOpen}
                    onMove={handleLahoreMapMove}
                    onZoomChange={handleZoom}
                  />
                </div>
              </div>
            </div>
            {isOverlay && (
              <div
                style={{
                  marginBottom: '20px',
                  color: 'black',
                  marginTop: '40px',
                }}
              >
                <label>Opacity: </label>
                <input
                  type='range'
                  min='0'
                  max='1'
                  step='0.1'
                  value={opacity}
                  onChange={(e) => setOpacity(e.target.value)}
                  style={{
                    width: screenWidth < 768 ? '100px' : '300px',
                    margin: '20px 10px',
                    color: 'black',
                  }}
                />
              </div>
            )}
          </div>
        </>
      )}

      <div ref={lastElementRef} className='hidable-zoom-controls-small-screen'>
        <ZoomControls handleZoomIn={handleZoomIn} handleZoomOut={handleZoomOut} />
      </div>
      <div className='d-flex justify-content-center mt-80 '>
        {isShareURL ? (
          <>
            <div
              className={`clipboard-container position-relative ${
                screenWidth > 768 ? 'w-50' : 'w-75'
              }`}
            >
              <h4 className='mb-0 share-url-text'>Share URL</h4>
              <a href={advisorURL} target='_blank' rel='noreferrer'>
                {advisorURL}
              </a>
              <br />
              <br />
              <CopyToClipboard text={advisorURL} onCopy={handleCopy}>
                <div className='position-absolute clipboard-icon' onClick={handleCopy}>
                  {copied ? (
                    <p>Copied!</p>
                  ) : (
                    <KTSVG path='/media/icons/duotune/general/gen054.svg' className='svg-icon-2x' />
                  )}
                </div>
              </CopyToClipboard>
            </div>
          </>
        ) : null}
      </div>
    </div>
  )
}

export default DraggableMaps
