import React from 'react'
import {TooltipProps} from 'recharts'
import {toUSD} from '../../../helper/charts.helper'
const MortgageTooltip: React.FC<TooltipProps<number, string>> = ({active, payload}) => {
  if (!active || !payload || payload.length === 0 || payload[0]?.payload?.label === 'Custom') {
    return null
  }

  const data = payload[0].payload
return (
  <div className='custom-tooltip sbloc-tooltip-container'>
    <span className='label-bold'>This Year - {data.year}</span>

    {/* deposits */}
    <div className='d-flex justify-content-between align-items-center mt-6'>
      <p className='label-bold paragraph-x-small label-initial-deposit mb-0'>Begining Amount</p>
      <p className='label-bold paragraph-x-small label-initial-deposit mb-0'>
        {toUSD(data.beginningBalance)}
      </p>
    </div>
    {/* gains */}
    <div className='d-flex justify-content-between align-items-center'>
      <p className='label-bold paragraph-x-small mb-0 label-initial-deposit'>Ending Balance</p>
      <p className='label-bold paragraph-x-small mb-0 label-initial-deposit'>
        {toUSD(data.endingBalance)}
      </p>
    </div>
    {/* re-investment gains */}
    <div className='d-flex justify-content-between align-items-center'>
      <p className='label-bold paragraph-x-small mb-0 label-re-invest'>Principal Paid</p>
      <p className='label-bold paragraph-x-small mb-0 label-re-invest'>
        {toUSD(data.principalPaid)}
      </p>
    </div>
    <div className='d-flex justify-content-between align-items-center'>
      <p className='label-bold paragraph-x-small mb-0 label-re-invest'>Total Principal Paid</p>
      <p className='label-bold paragraph-x-small mb-0 label-re-invest'>
        {toUSD(data.cumulative_principal_paid)}
      </p>
    </div>
    {/* borrowed */}
    <div className='d-flex justify-content-between align-items-center'>
      <p className='label-bold paragraph-x-small mb-0 label-borrow'>Interest Paid</p>
      <p className='label-bold paragraph-x-small mb-0 label-borrow'>{toUSD(data.interestPaid)}</p>
    </div>
    {/* interest */}
    <div className='d-flex justify-content-between align-items-center'>
      <p className='label-bold paragraph-x-small mb-0 label-borrow'>Total Interest Paid</p>
      <p className='label-bold paragraph-x-small mb-0 label-borrow'>
        {toUSD(data.cumulativeInterestPaid)}
      </p>
    </div>
  </div>
)

}

export default MortgageTooltip
