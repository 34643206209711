export function calculateMortgageValues(
  home_price: number,
  down_payment: number,
  loan_years: number,
  interest_rate: number
) {
  const loan_amount = home_price - down_payment // Loan amount
  const monthly_interest_rate = interest_rate / 100 / 12 // Monthly interest rate
  const total_months = loan_years * 12 // Total number of months

  // Monthly payment using the amortization formula
  const monthly_payment =
    (loan_amount * monthly_interest_rate) / (1 - Math.pow(1 + monthly_interest_rate, -total_months))

  const results = []
  let remaining_balance = loan_amount
  let cumulative_interest_paid = 0
  let cumulative_principal_paid = 0
  for (let year = 1; year <= loan_years; year++) {
    const beginning_balance = remaining_balance
    let interest_paid = 0
    let principal_paid = 0

    for (let month = 1; month <= 12; month++) {
      const interest_for_month = remaining_balance * monthly_interest_rate
      const principal_for_month = monthly_payment - interest_for_month

      interest_paid += interest_for_month
      principal_paid += principal_for_month

      remaining_balance -= principal_for_month

      // Stop the loop if the loan is fully paid
      if (remaining_balance <= 0) {
        remaining_balance = 0
        break
      }
    }
    cumulative_interest_paid += interest_paid
    cumulative_principal_paid+= principal_paid
    results.push({
      year,
      beginningBalance: parseFloat(beginning_balance.toFixed(2)),
      interestPaid: parseFloat(interest_paid.toFixed(2)),
      principalPaid: parseFloat(principal_paid.toFixed(2)),
      endingBalance: parseFloat(remaining_balance.toFixed(2)),
      cumulativeInterestPaid: parseFloat(cumulative_interest_paid.toFixed(2)), // Add cumulative interest
      interest_rate: interest_rate,
      cumulative_principal_paid: parseFloat(cumulative_principal_paid.toFixed(2)),
    })

    // Stop the loop if the loan is fully paid
    if (remaining_balance <= 0) {
      break
    }
  }

  return results
}
