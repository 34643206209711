import {useEffect, memo, useState} from 'react'
import {MORTGAGE_INPUT_BOX_TOOLTIPS, SBLOC_INPUT_BOX_TOOLTIPS} from '../../../../../constants/AppConstants'
import CustomOverlayTooltip from '../../projections/components/CustomOverlayTooltip'
import {useSBLOCProjections} from '../../../utils/sblocUtils'
import { useMortgageProjections } from '../../../utils/mortgageUtils'
import CustomInputField from '../../../../../components/customInputField/CustomInputField'
import ProjectionSlider from '../../../../../components/projectionSlider/ProjectionSlider'
import {SBLOCDataModel, SBLOCObjModel, SBLOCInputsProps, MortgageInputProps} from '../../../helper/SBLOC/_model'
import { MortgageModel } from '../../../helper/Mortgage/_model'
import useScreenWidth from '../../../hooks/useScreenWidth'
import {contributionAndDividendsFrequency} from '../../projections/components/ProjectionsConfig'
let barsDataArrayToShowOnChart: any[] = []

const MortgageInputs: React.FC<MortgageInputProps> = (props) => {
  let dataObj: MortgageModel
  // Destructuring custom(useProjectionsState) state
  const {isFirstRender, initialValues, handleKeyPress, formik, generateData} =
    useMortgageProjections()
  const screenWidth = useScreenWidth()
  const [maxLimit, setMaxLimit] = useState<number>(10000000)
  useEffect(() => {
    if (isFirstRender.current) {
      barsDataArrayToShowOnChart = generateData(initialValues)
      props.onChangeValues(formik.values)
      isFirstRender.current = false
      props.onBarsArrayUpdate(barsDataArrayToShowOnChart)
      return
    }
    if (!props.isFirstRowOfTableChanged) {
      let maxLimit = functionToSetMaxLimit(formik.values.home_price)
      setMaxLimit(maxLimit)
      dataObj = formik.values
      barsDataArrayToShowOnChart = []
      barsDataArrayToShowOnChart = generateData(dataObj)
      props.onBarsArrayUpdate(barsDataArrayToShowOnChart)
      props.onChangeValues(formik.values)
    }
  }, [formik.values])
  useEffect(() => {
    if (props.isUndo) {
      formik.setValues(initialValues)
      props.resetUndo()
    }
  }, [props.isUndo])
  // useEffect(() => {
  //   if (props.sharedSbLocInputs && Object.keys(props.sharedSbLocInputs).length > 1) {
  //     formik.setFieldValue('home_price', props.sharedSbLocInputs.home_price)
  //     formik.setFieldValue('loan_years', props.sharedSbLocInputs.loan_years)
  //     formik.setFieldValue('down_payment', props.sharedSbLocInputs.down_payment)
  //     formik.setFieldValue('interest_rate', props.sharedSbLocInputs.reInvested_ror)
  //   }
  // }, [props.sharedSbLocInputs])
  useEffect(() => {
    if (props.isFirstRowOfTableChanged && props.firstTableRowValue) {
      Object.entries(props.firstTableRowValue).forEach(([key, value]) => {
        const numValue = parseFloat(value as string)
        if (!isNaN(numValue)) {
          formik.setFieldValue(key, numValue)
        } else {
          console.warn(`Invalid number conversion for field '${key}' with value '${value}'`)
        }
      })
    }
  }, [props.isFirstRowOfTableChanged]) // Added props.firstTableRowValue to dependencies

  function functionToSetMaxLimit(input: number): number {
    if (input >= 0 && input < 100000) {
      return 100000 // Max limit set to 100K when input is below 100K
    } else if (input >= 100000 && input < 300000) {
      return 300000 // Max limit set to 300K when input is between 100K and 300K
    } else if (input >= 300000 && input < 1000000) {
      return 1000000 // Max limit set to 1M when input is between 300K and 1M
    } else if (input >= 1000000 && input < 10000000) {
      return 10000000 // Max limit set to 10M when input is between 1M and 10M
    } else if (input >= 10000000 && input < 30000000) {
      return 30000000 // Max limit set to 30M when input is between 10M and 30M
    } else if (input >= 30000000 && input < 100000000) {
      return 100000000 // Max limit set to 100M when input is between 30M and 100M
    } else {
      return 100000000 // Default return value for inputs outside the defined ranges
    }
  }

 
  return (
    <>
      <div>
        <div>
          {/* Projections form */}
          <form onSubmit={formik.handleSubmit} noValidate className='form' id='projection-form'>
            <div
              className={`row m-0 ${
                screenWidth > 746 ? 'justify-content-center' : 'justify-content-start mr-10'
              }`}
            >
              {/* Starting Amount and Asset Rate Of Return fields (mobile) */}
              <div className='col-6 col-md-2 fv-row mb-0 mb-lg-0 ps-0'>
                <div className='d-flex align-items-baseline'>
                  <p className='ps-1 m-0 fields-label'>Home Price</p>
                  <CustomOverlayTooltip
                    content={MORTGAGE_INPUT_BOX_TOOLTIPS.Home_Amount}
                    className='tooltip-logo'
                  />
                </div>
                <div
                  className={`input-container d-flex justify-content-center align-items-center ${
                    formik.touched.home_price && formik.errors.home_price
                      ? 'error-border'
                      : ''
                  }`}
                >
                  <CustomInputField
                    id='home_price'
                    className='form-control form-control-lg form-control-solid'
                    placeholder=' e.g 1000.00'
                    decimalsLimit={2}
                    maxLength={8}
                    defaultValue={formik.values.home_price}
                    onValueChange={(value: any) => {
                      const parsedValue = isNaN(parseFloat(value)) ? '' : parseInt(value!)
                      formik.setFieldValue('home_price', parsedValue)
                    }}
                    onKeyDown={handleKeyPress}
                    value={formik.values.home_price}
                  />
                </div>
                <ProjectionSlider
                  value={formik.values.home_price}
                  maxValue={maxLimit}
                  onRangeChange={(value: any) =>
                    formik.setFieldValue('home_price', parseInt(value!))
                  }
                  isWaystoneSlider={props.IsWayStoneSlider ? true : false}
                  sliderColor={props?.sliderColor}
                />
              </div>

              {/* Borrowed Amount and Annualized Interest Rate fields (mobile) */}
              <div className='col-6 col-md-2 fv-row mb-3 mb-lg-0 ps-0'>
                <div className='d-flex align-items-baseline'>
                  <p className='ps-1 m-0 fields-label'>Down Payment</p>
                  <CustomOverlayTooltip
                    content={MORTGAGE_INPUT_BOX_TOOLTIPS.DOWN_PAYMENT}
                    className='tooltip-logo'
                  />
                </div>
                <div
                  className={`input-container d-flex justify-content-center align-items-center ${
                    formik.touched.down_payment && formik.errors.down_payment
                      ? 'error-border'
                      : ''
                  }`}
                >
                  <CustomInputField
                    id='down_payment'
                    className='form-control form-control-lg form-control-solid'
                    placeholder=' e.g 1000.00'
                    decimalsLimit={2}
                    maxLength={8}
                    defaultValue={formik.values.down_payment}
                    onValueChange={(value: any) => {
                      const parsedValue = isNaN(parseFloat(value)) ? '' : parseInt(value!)
                      formik.setFieldValue('down_payment', parsedValue)
                    }}
                    onKeyDown={handleKeyPress}
                    value={formik.values.down_payment}
                  />
                </div>
                <ProjectionSlider
                  value={formik.values.down_payment}
                  minValue={100}
                  maxValue={maxLimit / 2}
                  onRangeChange={(value: any) =>
                    formik.setFieldValue('down_payment', parseInt(value!))
                  }
                  isWaystoneSlider={props.IsWayStoneSlider ? true : false}
                  sliderColor={props?.sliderColor}
                />
              </div>
              {/* Years to Grow */}
              <div className='col-6 col-md-2 fv-row mb-3 mb-lg-0 ps-0 ps-md-3'>
                <div className='d-flex align-items-baseline'>
                  <p className='ps-1 m-0 fields-label'>Loan Years</p>
                  <CustomOverlayTooltip
                    content={MORTGAGE_INPUT_BOX_TOOLTIPS.LOAN_YEARS}
                    className='tooltip-logo'
                  />
                </div>
                <div
                  className={`input-container d-flex justify-content-center align-items-center ${
                    formik.touched.loan_years && formik.errors.loan_years
                      ? 'error-border'
                      : ''
                  }`}
                >
                  <CustomInputField
                    id='loan_years'
                    className='form-control form-control-lg form-control-solid'
                    placeholder=' e.g 1000.00'
                    decimalsLimit={0}
                    maxLength={8}
                    defaultValue={formik.values.loan_years}
                    prefix=''
                    onValueChange={(value: any) => {
                      const parsedValue = isNaN(parseFloat(value)) ? '' : parseInt(value!)
                      formik.setFieldValue('loan_years', parsedValue)
                    }}
                    onKeyDown={handleKeyPress}
                    value={formik.values.loan_years}
                  />
                  {/* <input
                    id='loan_years'
                    type='number'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='e.g 10'
                    {...formik.getFieldProps('loan_years')}
                    onKeyDown={handleKeyPress}
                  /> */}
                </div>
                <ProjectionSlider
                  value={formik.values.loan_years}
                  minValue={1}
                  maxValue={100}
                  isMoney={false}
                  onRangeChange={(value: any) =>
                    formik.setFieldValue('loan_years', parseInt(value!))
                  }
                  isWaystoneSlider={props.IsWayStoneSlider ? true : false}
                  sliderColor={props?.sliderColor}
                />
              </div>

              <div className='col-6 col-md-2 fv-row mb-0 mb-lg-0 ps-md-3'>
                <div className='d-flex align-items-baseline'>
                  {/* changed Annualized ROR to APR  */}
                  <p className='ps-1 m-0 fields-label'>Interest Rate</p>
                  <CustomOverlayTooltip
                    content={MORTGAGE_INPUT_BOX_TOOLTIPS.INTEREST_RATE}
                    className='tooltip-logo'
                  />
                </div>
                <div
                  className={`input-container d-flex justify-content-center align-items-center ${
                    formik.touched.interest_rate &&
                    formik.errors.interest_rate
                      ? 'error-border'
                      : ''
                  }`}
                  // style={{width: screenWidth > 746 ? '6rem' : '100%'}}
                >
                  <CustomInputField
                    id='interest_rate'
                    className='form-control form-control-lg form-control-solid'
                    placeholder=' e.g 1000.00'
                    decimalsLimit={2}
                    maxLength={8}
                    prefix='%'
                    defaultValue={formik.values.interest_rate}
                    onValueChange={(value: any) => {
                      formik.setFieldValue('interest_rate', value || '')
                    }}
                    onKeyDown={handleKeyPress}
                    value={formik.values.interest_rate}
                  />
                </div>
                <ProjectionSlider
                  value={formik.values.interest_rate}
                  minValue={1}
                  maxValue={25}
                  isMoney={false}
                  onRangeChange={(value: any) =>
                    formik.setFieldValue('interest_rate', parseInt(value!))
                  }
                  isWaystoneSlider={props.IsWayStoneSlider ? true : false}
                  sliderColor={props?.sliderColor}
                />
              </div>
            </div>

            {/* Error messages */}
            <div className='text-center mt-2'>
              {formik.touched.home_price && formik.errors.home_price ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'> * {formik.errors.home_price}</div>
                </div>
              ) : null}
              {formik.touched.loan_years && formik.errors.loan_years ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'> * {formik.errors.loan_years}</div>
                </div>
              ) : null}
              {formik.touched.down_payment && formik.errors.down_payment ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'> * {formik.errors.down_payment}</div>
                </div>
              ) : null}
              {formik.touched.interest_rate && formik.errors.interest_rate ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'> * {formik.errors.interest_rate}</div>
                </div>
              ) : null}
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default memo(MortgageInputs)
