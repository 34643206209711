import {memo, useEffect, useRef, useState, useCallback} from 'react'
import useScreenWidth from '../../../hooks/useScreenWidth'
import MortgageTable from './MortgageTable'
import MortgageTooltip from './MortgageTooltip'
import {
  BarChart,
  Bar,
  XAxis,
  ResponsiveContainer,
  Tooltip,
  LabelList,
  Text,
  YAxis,
  LineChart,
  CartesianGrid,
  Legend,
  Line,
  ReferenceArea,
  Rectangle,
  ReferenceLine,
} from 'recharts'
import {toAbbreviatedNumber} from '../../../helper/charts.helper'
import {projectionsColors} from '../../PastPerformance/components/PastPerformanceConfig'
import {SBLOCChartProps} from '../../../helper/SBLOC/_model'
import SBLOCInputMOdal from './MortgageInputModal'
import {Button} from 'react-bootstrap'
import {downloadChart} from '../../../helper/charts.helper'
import {handleSBLOCActions} from '../../../advisor/core/_request'
import {useSBLOCContext} from '../../../../context/SBLOCContext'
import IBLOCTable from '../../IBLOC/Components/IBLOCTable'
const MortgageChart: React.FC<SBLOCChartProps> = (props) => {
  const {parentUserId} = useSBLOCContext()
  let {data: initialData} = props
  // const [initialData, setData] = useState(initialData)
  const [isLineGraph, setIsLineGraph] = useState(false)
  const [isLevargeView, setIsLevargeView] = useState(false)
  const [editInputs, setEditInputs] = useState(false)
  const [showTable, setShowTable] = useState(false)
  const [isToolTips, setIsToolTips] = useState(false)
  const [barsToAnimate, setBarsToAnimate] = useState(true)
  const [initialDataObjForInputModal, setInitialDataObjForInputModal] = useState<any>(initialData[0])
  const [yearToBeEdit, setYearToBeEdit] = useState<number[]>([])
  const [referenceBarLabel, setReferenceBarLabel] = useState<number[]>([])
  const [isLoading, setisLoading] = useState<boolean>(false)
  const [cursorStyle, setCursorStyle] = useState('default')
  const [isSegmentDraggable, setisSegmentDraggable] = useState<boolean>(false)
  const [isSegmentDragging, setisSegmentDragging] = useState<boolean>(false)
  const [yAxisCursorValue, setyAxisCursorValue] = useState(0)
  const [lastSegmentDragTime, setlastSegmentDragTime] = useState(0)
  const [prevDepositValue, setprevDepositValue] = useState(0)
  const [isDragging, setIsDragging] = useState(false)
  const screenWidth = useScreenWidth()

  // getting values from sbloc input modal
  const handleModalValues = (values: any) => {
    props.editYear(values, initialData)
  }
 

  useEffect(() => {
    setBarsToAnimate(true)
    setReferenceBarLabel([])
    setYearToBeEdit([])
  }, [initialData])
  // calculating chart height
  let chartPadding: any
  let _chartHeight: any
  // calculating chart padding
  chartPadding =
    screenWidth < 546
      ? {top: 50, bottom: 40, right: 30, left: -30}
      : {
          top: props.isWayStoneStyled ? 20 : 80,
          bottom: props.isWayStoneStyled ? 50 : 60,
          right: 40,
          left: -30,
        }

  _chartHeight = screenWidth > 767 ? 410 : screenWidth > 567 ? 250 : 200
  const labelPadding = screenWidth < 776 ? 8 : 0
  const labelTextAngle = screenWidth < 776 ? -30 : 0

  // customized bar top label
  const CustomizedBarTopLabel = (props: any) => {
    const {x, y, index, width, payload} = props
    const futureValue = initialData[index].endingBalance
     
    const gain = '$' + toAbbreviatedNumber(parseFloat(futureValue))
    const onClickLabel = () => {
      handleBarOnClick(initialData[index], index) // Call the handler with the correct initialData
    }
    return (
      <Text
        x={!isLineGraph ? x + width / 2 : x}
        y={!isLineGraph ? (screenWidth > 767 ? y - 35 : y - 15) : y - 10}
        textAnchor='middle'
        className='custom-label'
        fill='#9b258f'
        angle={initialData.length <= 10 ? 0 : 270}
        onClick={onClickLabel}
      >
        {isLevargeView && index === 0 ? 'Wealth' : gain}
      </Text>
    )
  }

  // custom bottom labels of the chart
  const CustomizedAxisTick = (textProps: any) => {
    const {x, y, index} = textProps
    return (
      <>
        {!isLevargeView && (
          <Text
            x={x}
            y={y + labelPadding}
            width={90}
            textAnchor='middle'
            verticalAnchor='start'
            angle={labelTextAngle}
            className='custom-tick-label'
          >
            {initialData[index]?.year}
          </Text>
        )}

        {/* {isLevargeView && (
          <>
            <Text
              x={x}
              y={y + labelPadding + (screenWidth > 748 ? 20 : 15)}
              width={90}
              textAnchor='middle'
              verticalAnchor='start'
              // angle={labelTextAngle}
              style={{color: 'black'}}
            >
              {initialData[index]?.axisLabel || ''}
            </Text>
            <Text
              x={x}
              y={y + labelPadding + (screenWidth > 748 ? 45 : 35)}
              width={90}
              textAnchor='middle'
              verticalAnchor='start'
              fill={props.isWayStoneStyled ? '#C6856B' : 'red'}
            >
              {isLevargeView && index === 0
                ? 'Cash'
                : isLevargeView && index === 1
                ? '$0'
                : isLevargeView && index === 2
                ? `$${toAbbreviatedNumber(totalDraw)}`
                : `$${toAbbreviatedNumber(cashForLastBarOfLevergaeView)}`}
            </Text>
          </>
        )} */}
      </>
    )
  }
  useEffect(() => {
    setisLoading(true)
    setTimeout(() => {
      setisLoading(false)
    })
  }, [initialData])
  const hightLightEditableBars = (payload: any, index: number) => {
    // Check if the year is already included in the yearToBeEdit array
    if (yearToBeEdit.includes(payload.year) || referenceBarLabel.includes(payload.year)) {
      // If it is included, remove it
      const newYearToBeEdit = yearToBeEdit.filter((year) => year !== payload.year)
      const newReferenceLabels = referenceBarLabel.filter((year) => year !== payload.year)
      setYearToBeEdit(newYearToBeEdit)
      setReferenceBarLabel(newReferenceLabels)
    } else {
      // If it's not included, add it
      setYearToBeEdit([...yearToBeEdit, payload.year])
      setReferenceBarLabel([...referenceBarLabel, initialData[index].year])
    }
  }

  const handleChartOnMouseMove = (chartClickData: any) => {
    if (!chartClickData || !chartClickData.activePayload) {
      return
    }

    const drawableHeight = _chartHeight - 40 - chartPadding.top - chartPadding.bottom

    const [, maxY] = calculateDomain()

    const scaleFactor = drawableHeight / maxY

    const translateChartYToYAxisValue = (chartY: any) => {
      const yOffset = chartPadding.top
      const drawableY = chartY - yOffset
      const yAxisValue = (drawableHeight - drawableY) / scaleFactor
      return yAxisValue
    }

    const yAxisValue = translateChartYToYAxisValue(chartClickData.chartY)
    setyAxisCursorValue(yAxisValue)

    const depositValue = chartClickData.activePayload[0].value
    const gainsValue = chartClickData.activePayload[1].value
    const reinvestedValue =
      chartClickData.activePayload[2].value >
      initialData[chartClickData.activeTooltipIndex].reInvested_asset
        ? chartClickData.activePayload[2].value
        : 0
    const borrowedValue = -chartClickData.activePayload[3].value // Convert to positive for calculations

    // Calculate cumulative height of segments growing upward
    const heightDeposit = depositValue
    const heightGains = gainsValue
    const heightReinvested = heightGains + reinvestedValue - borrowedValue
    // Base line for the borrowed segment (grows downward)
    const baselineForBorrowed = heightReinvested
    const endOfBorrowed = baselineForBorrowed + borrowedValue
    // Determine which segment the yAxisValue falls into
    if (yAxisValue >= 0 && yAxisValue < heightDeposit) {
    } else if (yAxisValue >= heightDeposit && yAxisValue < heightGains) {
    } else if (
      yAxisValue >= heightGains &&
      yAxisValue < heightReinvested &&
      yAxisValue < baselineForBorrowed
    ) {
    } else if (yAxisValue >= baselineForBorrowed && yAxisValue < endOfBorrowed) {
    } else {
    }
    if (Math.abs(yAxisValue - heightDeposit) < 0.05 * heightDeposit) {
      setCursorStyle('ns-resize')
      setisSegmentDraggable(true)
    } else {
      setisSegmentDraggable(false)
      setCursorStyle('default')
    }
  }

  const handleBarOnMouseDown = (payload: any, index: number) => {
    if (isSegmentDraggable) {
      setisSegmentDragging(true)
      setprevDepositValue(index > 0 ? initialData[index - 1].deposit : 0)
      setlastSegmentDragTime(Date.now())
    }
  }
  const handleBarOnMouseUp = (payload: any, index: number) => {
    setisSegmentDragging(false)
  }
  const handleBarOnMouseMove = (payload: any, index: number) => {
    if (!isSegmentDragging) {
      return
    }

    if (Date.now() - lastSegmentDragTime > 500) {
      setisSegmentDragging(false)
      return
    }
    setlastSegmentDragTime(Date.now())
    var newStartingValue = yAxisCursorValue - prevDepositValue
    // if (newStartingValue < 0) {
    //   newStartingValue = 0
    // }
    updateStartingAmount(newStartingValue, index)
  }

  const updateStartingAmount = (amount: number, index: number) => {
    var newval = {...initialData[index]}
    if (newval.starting_amount == amount) {
      return
    }
    newval.startingAmount = amount
    newval.borrowedAmount = newval.borrowed_amount
    newval.reInvestedAmount = newval.reInvested_asset
    newval.APR = newval.APR
    newval.ROR = newval.ROR
    newval.reInvestedRor = newval.reInvested_ror
    newval.cash_flow_that_year = newval.cash_flow_that_year
    newval.cash_flow_frequency = newval.cash_flow_frequency
    newval.subsequentYears = false
    props.editYear({...newval}, initialData)
  }

  const handleBarOnClick = (payload: any, index: number) => {
    setBarsToAnimate(false)
    if (!referenceBarLabel.includes(initialData[index].year) && !showTable) {
      setReferenceBarLabel([initialData[index].year])
      setYearToBeEdit([initialData[index].year])
    }
    if (showTable) {
      hightLightEditableBars(payload, index)
    } else {
      setEditInputs(true)
      setInitialDataObjForInputModal(initialData[index])
    }
  }
  const handleEditAbleIndexFromTable = (selectedIndexes: any, isDragging: boolean) => {
    setBarsToAnimate(false)
    if (selectedIndexes.length > 1) {
      let years: any = []
      selectedIndexes.forEach((item: any) => {
        years.push(initialData[item].year)
      })
      setYearToBeEdit([...years])
      setReferenceBarLabel(years)
    } else {
      if (yearToBeEdit.includes(initialData[selectedIndexes].year) && !isDragging) {
        // If it is included, remove it
        const newYearToBeEdit = yearToBeEdit.filter((year) => year !== initialData[selectedIndexes].year)
        const newReferenceLabels = referenceBarLabel.filter(
          (year) => year !== initialData[selectedIndexes].year
        )
        setYearToBeEdit(newYearToBeEdit)
        setReferenceBarLabel(newReferenceLabels)
      } else {
        // If it's not included, add it
        setYearToBeEdit([...yearToBeEdit, initialData[selectedIndexes].year])
        setReferenceBarLabel([...referenceBarLabel, initialData[selectedIndexes].year])
      }
    }
  }
  const handleIconClick = () => {
    const getLinkButton = document.getElementById('getLinkButton')
    if (getLinkButton) {
      getLinkButton.click()
    }
  }

  const calculateDomain = useCallback(() => {
    let lastKey = initialData[initialData.length - 1]
    const calc =
      lastKey?.gainOnStartingAmount +
      lastKey?.gainOnReInvestedAmount +
      lastKey?.growthForLineGraph -
      lastKey?.lossByBorrowedAmountForLineChart
    const dataMax = calc
    return [0, dataMax * 1.3]
  }, [initialData])

  // Define the type for the keys you expect in the radiusConfig
  type DataKey =
    | 'deposit'
    | 'lossByBorrowedAmountAfterAddingDraw'
    | 'cummulativeReInvestment'
    | 'lossByBorrowedAmountAfterAddingDrawForLeverageView'
    | 'futureValue'
  // Define the type for the radius array
  type RadiusArray = number[]
  function determineRadius(dataKey: DataKey, value: number): RadiusArray {
    const radiusConfig: Record<DataKey, RadiusArray> = {
      deposit: value > 0 ? [0, 0, 5, 5] : [0, 0, 0, 0],
      lossByBorrowedAmountAfterAddingDraw: value < 0 ? [0, 0, 5, 5] : [0, 0, 0, 0],
      lossByBorrowedAmountAfterAddingDrawForLeverageView: value > 0 ? [0, 0, 5, 5] : [0, 0, 0, 0],
      cummulativeReInvestment: value > 0 ? [5, 5, 0, 0] : [0, 0, 0, 0],
      futureValue: value > 0 ? [5, 5, 0, 0] : [0, 0, 0, 0],
    }
    return radiusConfig[dataKey] || [0, 0, 0, 0]
  }
  const CustomBar = (props: any) => {
    const {fill, x, y, width, height, payload} = props
    return (
      // eslint-disable-next-line react/jsx-no-undef
      <>
        <Rectangle
          {...props}
          fill={fill}
          stroke='transparent' // Border color
          strokeWidth={1} // Border width
          radius={determineRadius(
            props?.tooltipPayload[0]?.dataKey,
            payload?.[props?.tooltipPayload[0]?.dataKey]
          )}
        />
      </>
    )
  }
  const iconStyle = {
    fontSize: '1.5rem', // Make the icons bigger
    color: '#7c1e72', // Dark purple color
    cursor: 'pointer',
    marginBottom: '1rem', // Add spacing between icons
  }

  // const handleDragStart = () => {
  //   setIsDragging(true)
  //   setReferenceBarLabel([])
  //   setYearToBeEdit([])
  // }

  // const handleDragEnd = () => {
  //   setIsDragging(false)
  // }


  return (
    <>
      <div
        className={`${
          props.isWayStoneStyled ? '' : 'pt-20 pt-8 pt-7 '
        }px-md-10 px-lg-20 user-select-none`}
      >
        {!props.isWayStoneStyled && (
          <div
            style={{
              textAlign: 'center',
              width: '100%',
              backgroundColor: props.isWayStoneStyled ? '#d0cfcd' : 'white',
            }}
          >
            {isLevargeView ? (
              <h4 style={{color: 'black'}} className='mb-0 pt-10'>
                SBLOC Leverage...
              </h4>
            ) : (
              <>
                <div className='p-3 px-5 d-none d-md-block'>
                  {/* download chart image button */}
                  {/* <Button
                    className='mx-5 custom-primary-button'
                    onClick={() => setEditInputs(true)}
                  >
                    Edit Year
                  </Button> */}
                  {/* download chart image button */}
                  <Button className='custom-primary-button' onClick={() => props.undo()}>
                    Undo
                  </Button>
                  <Button
                    className='mx-5 custom-primary-button'
                    onClick={() => downloadChart('sbloc-chart-container', 'Vivid Projection.png')}
                  >
                    Download
                  </Button>
                </div>
                <div className='p-3 px-5 d-none d-md-block'></div>
              </>
            )}
          </div>
        )}

        {!isLoading && (
          <div
            className='d-flex align-items-center'
            // onMouseDown={handleDragStart}
            // onMouseUp={handleDragEnd}
            // onMouseLeave={handleDragEnd} // Stop dragging if the mouse leaves the chart
            // onTouchStart={handleDragStart}
            // onTouchEnd={handleDragEnd}
            // onTouchMove={handleTouchMove} // Handle touch move event
          >
            <ResponsiveContainer width='100%' height={400} id='sbloc-chart-container'>
              {!isLineGraph ? (
                <BarChart
                  margin={chartPadding}
                  data={initialData}
                  style={{backgroundColor: props.isWayStoneStyled || 'white', cursor: cursorStyle}}
                  barCategoryGap={isLevargeView ? '10%' : '20%'} // Adjust these values as needed
                  barGap={0} // Adjust this value to bring bars closer together
                  // onMouseMove={handleChartOnMouseMove}
                >
                  <XAxis
                    dataKey='year'
                    axisLine={false}
                    tickLine={false}
                    interval={0}
                    width={100}
                    minTickGap={5}
                    tick={<CustomizedAxisTick />}
                  />
                  {referenceBarLabel.map((line, index) => (
                    <ReferenceArea
                      x1={line} // Starting year (ensure it matches an actual initialData point)
                      x2={line} // Ending year (ensure it matches an actual initialData point)
                      stroke='green'
                      fill='green'
                      fillOpacity={0.3}
                      yAxisId='left'
                    />
                  ))}
                  {initialData.map((line: any, index: any) => (
                    <>
                      <ReferenceArea
                        x1={initialData[index].year}
                        x2={initialData[index].year}
                        stroke='transparent'
                        fill='transparent'
                        fillOpacity={0.3}
                        yAxisId='left'
                        initialData-index={index}
                      />
                    </>
                  ))}
                  <YAxis
                    yAxisId='left'
                    allowDataOverflow={true}
                    tick={false}
                    axisLine={false}
                    tickLine={false}
                    domain={
                      !isLevargeView
                        ? calculateDomain()
                        : [
                            (dataMin: number) => 0 - Math.abs(dataMin),
                            (dataMax: number) => dataMax * 1.3, // Increase dataMax by 10%
                          ]
                    }
                  />
                  {/* tooltip */}

                  {isToolTips && (
                    <Tooltip
                      contentStyle={{backgroundColor: 'white'}}
                      content={<MortgageTooltip />}
                    />
                  )}

                  {/* red */}
                  {!isLevargeView ? (
                    <>
                      <Bar
                        dataKey='endingBalance'
                        stackId='assets'
                        barSize={70}
                        yAxisId='left'
                        fill={
                          isLevargeView
                            ? projectionsColors.initialDeposit
                            : projectionsColors.initialDeposit
                        }
                        onClick={handleBarOnClick}
                        // onMouseDown={handleBarOnMouseDown}
                        // onMouseUp={handleBarOnMouseUp}
                        // onMouseMove={handleBarOnMouseMove}
                        isAnimationActive={barsToAnimate}
                        shape={<CustomBar />} // Custom shape
                      />
                      {/* <Bar
                        //light pink
                        barSize={70}
                        dataKey='endingBalance'
                        stackId='assets'
                        fill={
                          isLevargeView ? projectionsColors.initialDeposit : projectionsColors.gain
                        }
                        yAxisId='left'
                        onClick={handleBarOnClick}
                        onMouseDown={handleBarOnMouseDown}
                        onMouseUp={handleBarOnMouseUp}
                        onMouseMove={handleBarOnMouseMove}
                        isAnimationActive={barsToAnimate}
                        shape={<CustomBar />} // Custom shape
                      ></Bar> */}
                      {/* purple */}
                      <Bar
                        dataKey='principalPaid'
                        stackId='assets'
                        barSize={70}
                        yAxisId='left'
                        fill={
                          isLevargeView
                            ? projectionsColors.initialDeposit
                            : projectionsColors.gainOnReInvestment
                        }
                        onClick={handleBarOnClick}
                        // onMouseDown={handleBarOnMouseDown}
                        // onMouseUp={handleBarOnMouseUp}
                        // onMouseMove={handleBarOnMouseMove}
                        isAnimationActive={barsToAnimate}
                        shape={<CustomBar />} // Custom shape
                      ></Bar>
                      <Bar
                        barSize={70}
                        dataKey={'cumulativeInterestPaid'}
                        stackId='assets'
                        fill={projectionsColors.borrow}
                        yAxisId='left'
                        onClick={handleBarOnClick}
                        // onMouseDown={handleBarOnMouseDown}
                        // onMouseUp={handleBarOnMouseUp}
                        // onMouseMove={handleBarOnMouseMove}
                        isAnimationActive={barsToAnimate}
                        shape={<CustomBar />} // Custom shape
                      >
                        {initialData.length <= 20 ? (
                          <LabelList
                            content={<CustomizedBarTopLabel />}
                            position='top'
                            key={new Date().getTime()}
                          />
                        ) : null}
                      </Bar>
                    </>
                  ) : null}
                </BarChart>
              ) : (
                <LineChart
                  width={800}
                  height={250}
                  data={initialData}
                  margin={{top: 20, right: 30, left: 10, bottom: 5}}
                  style={{backgroundColor: 'white'}}
                >
                  <CartesianGrid strokeDasharray='3 3' />
                  <XAxis dataKey='year' />
                  <YAxis tickFormatter={toAbbreviatedNumber} />{' '}
                  {/* Hiding YAxis as we're displaying initialData on the lines */}
                  {/* <Tooltip /> */}
                  {isToolTips && (
                    <Tooltip
                      contentStyle={{backgroundColor: 'white'}}
                      content={<MortgageTooltip />}
                    />
                  )}
                  {/* <Legend /> */}
                  {/* Line for future value */}
                  <Line
                    type='monotone'
                    dataKey='beginningBalance'
                    stroke={projectionsColors.gainOnReInvestment}
                    strokeWidth={1}
                    name='Start Balance'
                  ></Line>
                  <Line
                    type='monotone'
                    dataKey='endingBalance'
                    stroke={projectionsColors.gain}
                    strokeWidth={1}
                    name='Ending Balance'
                  >
                    <LabelList content={<CustomizedBarTopLabel />} position='top' />
                  </Line>
                  <Line
                    type='monotone'
                    dataKey='interestPaid'
                    stroke={projectionsColors.borrow}
                    strokeWidth={1}
                    name='interest'
                  />
                  <Line
                    type='monotone'
                    dataKey='cumulative_principal_paid'
                    stroke={projectionsColors.initialDeposit}
                    strokeWidth={1}
                    name='principal'
                  />
                  <Line
                    type='monotone'
                    dataKey='cumulativeInterestPaid'
                    stroke='black'
                    strokeWidth={1} // Adjust line thickness here
                    name='cummulative Interest'
                  ></Line>
                  <Legend />
                </LineChart>
              )}
            </ResponsiveContainer>
            {props.isWayStoneStyled && (
              <div className='d-flex flex-column align-items-center ps-6'>
                <i
                  className='fas fa-undo'
                  style={iconStyle}
                  onClick={() => {
                    props.undo()
                    handleSBLOCActions(parentUserId)
                  }}
                ></i>
                <i
                  className='fas fa-download'
                  style={iconStyle}
                  onClick={() => {
                    downloadChart('sbloc-chart-container', 'Vivid Projection.png')
                    handleSBLOCActions(parentUserId)
                  }}
                ></i>
                <i
                  className='fas fa-cog'
                  style={iconStyle}
                  onClick={() => {
                    setEditInputs(true)
                    handleSBLOCActions(parentUserId)
                  }}
                ></i>
                <i
                  className='fas fa-share'
                  style={iconStyle}
                  onClick={() => {
                    handleIconClick()
                    handleSBLOCActions(parentUserId)
                  }}
                ></i>
              </div>
            )}
          </div>
        )}
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-6 col-lg-3 mt-3 d-flex justify-content-center'>
              <div className='form-check form-switch d-flex align-items-center'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  role='switch'
                  id='flexSwitchCheckDefault1'
                  onClick={() => {
                    setShowTable(!showTable)
                    !showTable && handleSBLOCActions(parentUserId)
                  }}
                />
                <label
                  className='sliding-toggle-label form-check-label ms-2'
                  htmlFor='flexSwitchCheckDefault1'
                >
                  Show Table
                </label>
              </div>
            </div>
            <div className='col-6 col-lg-3 mt-3 d-flex justify-content-center'>
              <div className='form-check form-switch d-flex align-items-center'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  role='switch'
                  id='flexSwitchCheckDefault2'
                  onClick={() => {
                    setIsLineGraph(!isLineGraph)
                    !isLineGraph && handleSBLOCActions(parentUserId)
                  }}
                />
                <label
                  className='sliding-toggle-label form-check-label ms-2'
                  htmlFor='flexSwitchCheckDefault2'
                >
                  Line Graph
                </label>
              </div>
            </div>
            <div className='col-6 col-lg-3 mt-3 d-flex justify-content-center'>
              <div className='form-check form-switch d-flex align-items-center'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  role='switch'
                  id='flexSwitchCheckDefault3'
                  onClick={() => {
                    setIsToolTips(!isToolTips)
                  }}
                />
                <label
                  className='sliding-toggle-label form-check-label ms-2'
                  htmlFor='flexSwitchCheckDefault3'
                >
                  Rollovers
                </label>
              </div>
            </div>
          </div>
        </div>
        {showTable ? (
          <div className={`${isToolTips ? 'mt-100' : ''}`}>
            <MortgageTable
              tableData={props.tableData}
              onEditYear={props.editYearForTable}
              yearToBeEdit={yearToBeEdit}
              editableIndex={handleEditAbleIndexFromTable}
              firstRowInputs={props.handleChangeFirstRowInputs}
            />
          </div>
        ) : null}
        {/* {editInputs ? (
          <SBLOCInputMOdal
            show={editInputs}
            onHide={() => setEditInputs(false)}
            title={'Edit Inputs'}
            inititaldataobj={initialDataObjForInputModal}
            yearstogrow={initialDataObjForInputModal.years_to_grow}
            onSubmit={handleModalValues}
          />
        ) : null} */}
      </div>
    </>
  )
}

export default memo(MortgageChart)
